<template>
	<div class="cassie-vertical-md mt-1">
		<FilterBar
			:search-query.sync="cookieScriptSearchQuery"
			search-query-label="Cookie Script Name"
			:selected-brand-id.sync="selectedAdminPortalBrandId"
			@persistSearchQuery="changeCookieScriptSearchQuery"
			@persistSelectedBrandId="changeAdminPortalBrandFilter"
			@clearFilters="clearFilters"
		>
			<template #after-filters>
				<Dropdown
					v-model="selectedCookieScriptType"
					label="Cookie Script Type"
					:items="scriptTypeFilterItems"
					custom-sort
					@change="changeCookieScriptType"
				/>
				<Dropdown
					v-model="selectedCookieCategory"
					label="Cookie Category"
					:items="categoryFilterItems"
					custom-sort
					@change="changeCookieCategory"
				/>
			</template>
			<template #action>
				<PrimaryActionButton
					v-if="userCanCreateUpdate"
					@click="showManageModal = true"
				>
					<v-icon left>
						mdi-plus
					</v-icon>
					Create
				</PrimaryActionButton>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				Cookie Scripts
			</template>
			<template #body>
				<DataTable
					:items="filteredScripts"
					:headers="tableHeaders"
					sort-by="id"
					:sort-desc="true"
					@click:row="loadCookieScriptToEdit($event.cookieScriptChannelId)"
				>
					<template #item.type="{ item }">
						{{ item.isStrictlyCategory ? 'Strictly Necessary' : 'User Consent' }}
					</template>
					<template #item.usage="{ item }">
						{{ returnUsageCount(item) }}
					</template>
					<template #item.action="{ item }">
						<IconButton
							v-if="userCanCreateUpdate"
							tooltip-text="Edit Cookie Script"
							@click="loadCookieScriptToEdit(item.cookieScriptChannelId)"
						>
							mdi-pencil
						</IconButton>
						<IconButton
							v-else
							tooltip-text="View Cookie Script"
							@click="loadCookieScriptToEdit(item.cookieScriptChannelId)"
						>
							mdi-eye
						</IconButton>
						<IconButton
							v-if="userCanDelete && !item.countOfAssociatedCookieBanners && !item.countOfAssociatedCookieScripts"
							tooltip-text="Delete Cookie Script"
							@click.stop.prevent="scriptToRemove = item"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<CookieScriptModal
			v-if="showManageModal || cookieScriptToEdit"
			:cookie-script-to-edit="cookieScriptToEdit"
			:cookie-categories="cookieCategories"
			:transaction-types="transactionTypes"
			:user-full-permissions="userCanCreateUpdate"
			:gtm-cookie-scripts="gtmCookieScripts"
			@close="closeModal"
			@submit="submitCookieScript"
		/>
		<ConfirmDeleteModal
			v-if="scriptToRemove"
			:entity-name="scriptToRemove.cookieScriptName"
			entity-type="Cookie Script"
			@close="scriptToRemove = null"
			@delete="deleteScript"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import FilterBar from '../../../../../../../shared/components/filter-bar.vue'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../../../shared/components/data-table.vue'
import IconButton from '../../../../../../../shared/components/icon-button.vue'
import Dropdown from '../../../../../../../shared/components/dropdown.vue'
import CookieScriptModal from './cookie-script-modal.vue'
import ConfirmDeleteModal from '../../../../../../../shared/components/confirm-delete-modal.vue'
import { useLabels } from '../../../../../../../shared/state/admin-portal-navigation.js'
import { CAN_CREATE_UPDATE_COOKIE_SCRIPTS, CAN_DELETE_COOKIE_SCRIPTS } from '../../../../../../../shared/permissions/admin-portal-permissions.js'
import { deleteCookieScript, getCookieScript } from '../../../../../../../shared/utils/api/cookies/cookie-scripts.js'
import { cookieScriptSearchQuery, changeCookieScriptSearchQuery, changeCookieScriptType, selectedCookieScriptType, changeCookieCategory, selectedCookieCategory } from '../../../../../../../shared/state/cookie-scripts.js'
import { selectedAdminPortalBrandId, changeAdminPortalBrandFilter } from '../../../../../../../shared/state/brands.js'
export default {
	components: {
		DataTable,
		FilterBar,
		PrimaryActionButton,
		SectionCard,
		IconButton,
		Dropdown,
		CookieScriptModal,
		ConfirmDeleteModal
	},
	props: {
		cookieScripts: Array,
		cookieCategories: Array,
		transactionTypes: Array
	},
	setup () {
		return {
			useLabels,
			cookieScriptSearchQuery,
			changeCookieScriptSearchQuery,
			changeCookieScriptType,
			selectedCookieScriptType,
			changeCookieCategory,
			selectedCookieCategory,
			selectedAdminPortalBrandId,
			changeAdminPortalBrandFilter
		}
	},
	data () {
		return {
			showManageModal: false,
			cookieScriptToEdit: null,
			scriptToRemove: null
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		scriptTypeFilterItems () {
			return [
				{
					text: 'View All',
					value: null
				},
				{
					text: 'Essential',
					value: 'Essential'
				},
				{
					text: 'Legitimate Interest',
					value: 'Legitimate Interest'
				},
				{
					text: 'User Consent',
					value: 'User Consent'
				}
			]
		},
		categoryFilterItems () {
			return [
				{
					text: 'View All',
					value: null
				},
				...this.cookieCategories.map(({ id, categoryName }) => ({
					text: categoryName,
					value: id
				})).sort((a, b) => a.text.localeCompare(b.text))
			]
		},
		tableHeaders () {
			return [
				{ value: 'id', text: 'ID', width: '5%' },
				{ value: 'brandName', text: useLabels('Brand'), width: '15%' },
				{ value: 'cookieScriptName', text: 'Cookie Script Name', width: '20%' },
				{ value: 'cookieScriptType', text: 'Cookie Script Type', width: '10%' },
				{ value: 'cookieCategoryName', text: 'Cookie Category Type', width: '15%' },
				{ value: 'usage', text: 'Usage', width: '15%' },
				{ value: 'createdDate', text: 'Created Date', width: '10%' },
				{ value: 'action', text: 'Action', width: '15%', sortable: false }
			]
		},
		filteredScripts () {
			return this.cookieScripts.filter(({ cookieScriptName, brandId, cookieScriptType, cookieCategoryId }) => {
				let check = true
				if (cookieScriptSearchQuery.value) check = cookieScriptName.toLowerCase().includes(cookieScriptSearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && brandId === selectedAdminPortalBrandId.value
				if (selectedCookieScriptType.value !== null) check = check && cookieScriptType === selectedCookieScriptType.value
				if (selectedCookieCategory.value !== null) check = check && cookieCategoryId === selectedCookieCategory.value
				return check
			})
		},
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_COOKIE_SCRIPTS)
		},
		userCanDelete () {
			return this.productAreaPermission(CAN_DELETE_COOKIE_SCRIPTS)
		},
		gtmCookieScripts () {
			return this.cookieScripts.filter(({ gtmEnabled }) => gtmEnabled).map(cookieScript => {
				return {
					cookieCategoryId: cookieScript.cookieCategoryId,
					cookieScriptId: cookieScript.cookieScriptId,
					gtmConsentTypeId: cookieScript.gtmConsentTypeId,
					cookieCategoryName: cookieScript.cookieCategoryName
				}
			})
		}
	},
	methods: {
		returnUsageCount (script) {
			return `${script.countOfAssociatedCookieBanners} Cookie Banner${script.countOfAssociatedCookieBanners === 1 ? '' : 's'}`
		},
		clearFilters () {
			selectedCookieScriptType.value = null
			selectedCookieCategory.value = null
		},
		closeModal () {
			this.showManageModal = false
			this.cookieScriptToEdit = null
		},
		submitCookieScript () {
			this.closeModal()
			this.$emit('load')
		},
		async loadCookieScriptToEdit (cookieScriptChannelId) {
			const cookieScriptToEdit = await getCookieScript(cookieScriptChannelId)
			this.cookieScriptToEdit = cookieScriptToEdit
		},
		async deleteScript () {
			await deleteCookieScript(this.scriptToRemove.cookieScriptChannelId)
			this.scriptToRemove = null
			this.$emit('load')
		}
	}
}
</script>
