import { configApi } from '../http-client.js'

export const getTransactionTypes = () => {
	return configApi.get('/api/TransactionTypes')
}

export const deleteTransactionType = transactionTypeId => configApi.delete(`/api/TransactionTypes/${transactionTypeId}`)

export const createTransactionType = transactionType => {
	return configApi.post('/api/TransactionTypes', transactionType)
}
export const updateTransactionType = transactionType => {
	return configApi.put('/api/TransactionTypes', transactionType)
}
