<template>
	<ValidationForm #default="{ handleSubmit }">
		<Modal width="850px">
			<template #modal-title>
				{{ isEdit ? 'Edit' : 'Create' }} Cookie Script Translation
				<v-spacer />
				<IconButton @click="$emit('close')">
					mdi-close
				</IconButton>
			</template>
			<template #modal-content>
				<div class="cassie-vertical-md">
					<v-row dense>
						<v-col cols="12">
							<TextField
								:value="cookieScriptTranslation.languageName"
								disabled
								label="Language"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<TextField
								:value="cookieScriptTranslation.cookieScriptName"
								disabled
								label="Name"
								auto-grow
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<Textarea
								:value="cookieScriptTranslation.displayText"
								disabled
								label="Default Display Text"
								auto-grow
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<TextField
								v-model="translatedScriptName"
								:disabled="!userCanCreateUpdate"
								label="Translated Cookie Script Name *"
								:rules="{max: 150}"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<Froala
								v-if="userCanCreateUpdate"
								v-model="translatedDisplayText"
								title="Translated Display Text *"
								:rules="{required: true, max: 2000}"
							/>
							<Textarea
								v-else
								disabled
								:value="cookieScriptTranslation.translatedDisplayText"
								label="Translated Display Text *"
							/>
						</v-col>
					</v-row>
				</div>
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="$emit('close')">
					{{ userCanCreateUpdate ? 'Cancel' : 'Close' }}
				</SecondaryActionButton>
				<PrimaryActionButton
					v-if="userCanCreateUpdate"
					@click="handleSubmit(submit)"
				>
					{{ !isEdit ? 'Create' : 'Save' }}
				</PrimaryActionButton>
			</template>
		</Modal>
	</ValidationForm>
</template>

<script>
import Modal from '../../../../../../../shared/components/modal.vue'
import ValidationForm from '../../../../../../../shared/components/validation-form.vue'
import TextField from '../../../../../../../shared/components/text-field.vue'
import Textarea from '../../../../../../../shared/components/textarea.vue'
import Froala from '../../../../shared/froala-editor.vue'
import SecondaryActionButton from '../../../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../../../shared/components/icon-button.vue'
import { createCookieScriptTranslation, updateCookieScriptTranslation } from '../../../../../../../shared/utils/api/cookies/cookie-scripts.js'
import { showSnackbar } from '../../../../../../../shared/state/snackbar.js'

export default {
	components: {
		Modal,
		ValidationForm,
		IconButton,
		TextField,
		Textarea,
		Froala,
		SecondaryActionButton,
		PrimaryActionButton
	},
	props: {
		cookieScriptTranslation: {
			type: Object,
			required: true
		},
		userCanCreateUpdate: {
			type: Boolean,
			default: false
		}
	},
	setup () {
		return {
			showSnackbar
		}
	},
	data () {
		return {
			translatedScriptName: null,
			translatedDisplayText: null,
			translatedUrl: null
		}
	},
	computed: {
		isEdit () {
			return this.cookieScriptTranslation?.scriptTranslationId !== undefined && this.cookieScriptTranslation?.scriptTranslationId !== null
		}
	},
	created () {
		if (this.isEdit || !this.userCanCreateUpdate) {
			this.translatedDisplayText = this.cookieScriptTranslation?.translatedDisplayText ?? ''
			this.translatedScriptName = this.cookieScriptTranslation?.translatedScriptName ?? ''
		}
	},
	methods: {
		async submit () {
			const cookieScriptTranslation = {
				languageId: this.cookieScriptTranslation.languageId,
				cookieName: this.translatedScriptName,
				cookieDescription: this.translatedDisplayText,
				cookieUrl: this.translatedUrl, // this is always null as no UI component until told otherwise
				channelId: this.cookieScriptTranslation.cookieScriptChannelId
			}
			if (!this.isEdit) {
				await createCookieScriptTranslation(cookieScriptTranslation).then(response => {
					this.$emit('submit')
				})
				showSnackbar('Cookie Script created successfully')
			} else {
				await updateCookieScriptTranslation(cookieScriptTranslation)
				showSnackbar('Cookie Script created successfully')
			}
			this.$emit('submit')
		}
	}
}
</script>
